import {
  Box,
  Button,
  ButtonBase,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Select from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import { Container, height } from "@mui/system";
import axiosInstance from "../../axiosInterceptor";
import axiosInterceptor from "../../axiosInterceptor";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import UploadImage from "../BugReport/UploadImage";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Row, Col, Form, Card } from "react-bootstrap";
// import Select from "react-select";
import DownloadCard from "../Admin/DownloadData/DownloadCard";

import { useHistory } from "react-router-dom";
import CustomConfirm from "../../components/ConfirmAlert/CustomConfirm";

export const DataRequestPage = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const CustomBox = styled(Box)({
      "&.MuiBox-root": {
        backgroundColor: "#fff",
        borderRadius: "2rem",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: "1rem",
        margin: "1rem",
        height: "40%",
      },
      "&.MuiBox-root:hover, &.MuiBox-root.dragover": {
        opacity: 0.6,
      },
    });

  const [loading, setLoading] = useState(false);
  const [allSurveyIds, setAllSurveyIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]); //{"value":"cognition","label":"Need for Cognition Scale"}
  const [file, setFile] = useState([]);
  const [email, setEmail] = useState("test@gmail.com");
  const [url, setUrl] = useState("testurl") 


  const [downloads, setDownloads] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [allRequests,setAllRequests] =  useState(null);//my all requests
  const [selectDemos, setSelectDemos] = React.useState([]);
  const cachedCsv = {}

  const [allowedPageAccess, setAllowedPageAccess] = useState(false)

  let history = useHistory();
  let user;
  let demoPros = ["sex", "birthdate","age","birthCountry","motherCountry","fatherCountry","location","birthState","currentCountry","zipcode","currentState","first_language","years_of_residency","bilingual","social_opinion","economic_opinion","identify","marital_status","political_opinion","political_party_general","vote2020","race","gender_identity","religion","religious_degree","education","income","social_class","job_intelligence","job_creativity","job_independence","supervise","date","time","userId"]
  useEffect(() => {
    if (
      localStorage.getItem('user') === null ||
      localStorage.getItem('user') === undefined
    ) {
      history.push('/')
    } else {
      setEmail(JSON.parse(localStorage.getItem('user')).email)
    }
    // console.log("user:" + user.email);
  }, []);

  // const fetchMyRequests = async () => {
  //   try {
  //     if(userResult.data.user.requestAllow){
  //       const pendingRequests= await axiosInstance().get(
  //         "admin/getAllRequests/" + encodeURIComponent(email)
  //       );
  //       // console.log("getAllRequests:"+ JSON.stringify(pendingRequests.data.data))
  //       setAllRequests(pendingRequests.data.data)
  //     }
  //   }
  //   catch(e){
  //     console.log("updateUser() error:"+e)
  //   }
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // check if user is allowed to req for data
        const userResult = await axiosInstance().get('user/checkDataRequest')
        setAllowedPageAccess(userResult.data.requestAllow)
        //fetch all downloadbale survey from DB
        if (userResult.data.requestAllow) {
          const resp = await axiosInstance().get('user/all-download-id')
          if (resp.data.survey) {
            let allIDs = resp.data.survey
            allIDs = allIDs.map((item) => {
              return { value: item.id, label: item.name, type: item.type }
            })
            // console.log("allIDs:"+ JSON.stringify(allIDs))
            setAllSurveyIds(allIDs.reverse())
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    fetchData();
    getDownload();
  }, []);

  // useEffect(() => {
  //   console.log("selectedOption:" + selectedOption); //{"value":"cognition","label":"Need for Cognition Scale"}
  // }, [selectedOption]);


  const handleLabel = (event) => {
    setSelectedOption(event.target.value);
    if(!hasNewSurvey(event.target.value)){
      setSelectDemos([])//reset demo
      // console.log("handleLabel:"+setSelectDemos([]))
    }
    // console.log("handleLabel:"+JSON.stringify(event.target.value))

    // console.log(JSON.stringify(option)); //{"value":"cognition","label":"Need for Cognition Scale"}
  };


  const handleDemoPropChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectDemos(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onSubmit = async () => {
    // console.log("selectDemos:"+selectDemos)
    // console.log("selectedOption:"+JSON.stringify(selectedOption))
    const urls = [];
    try {
      for (let f of file) {
        //Step1: get s3 presigned url 
        const uploadConfig = await axiosInstance().post("report/upload", {
          name: f.name,
          type: f.type,
          source: "datarequest",
        });
        // console.log("uploadConfig:"+JSON.stringify(uploadConfig));
        // console.log(keyValue(f));
        urls.push(uploadConfig.data.key);
        console.log("type:" + f.type);

        //Step2: upload file using s3 presigened url
        await axios.put(uploadConfig.data.url, f, {
          headers: {
            "Content-Type": f.type,
          },
        });
        setUrl(urls[0]);

        //Step3: upload request
        await axiosInstance().post("report/add-request", {
          email: email,
          status: "pending",
          survey: selectedOption, //{"value":"cognition","label":"Need for Cognition Scale",type:"new/old"}
          urls: urls,
          selectDemos: selectDemos
        });
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card style={{ width: "25rem" }} className="confirmCard">
              <Card.Body>
                <Card.Title>Thank you</Card.Title>
                <Card.Text>
                  We have received your request, thank you!
                </Card.Text>
                <Container fluid>
                  <Row>
                    <Col xs={6} className="text-left">
                      <Button
                        variant="outline-success"
                        className="w-100"
                        onClick={() => {
                          if (history) {
                            history.push("/");
                          } else {
                            window.location = "/";
                          }
                          onClose();
                        }}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      setFile([]);
    }
  };

  const getDownload = useCallback(async () => {
    setLoading(true);
    try {
      // const api = `admin/list/${type}`;
      //get user's download list
      const api = `user/download-list`;
      const response = await axiosInterceptor().get(api);//{"type":"old","_id":"6170956d7e8aac27ba1e3138","surveyId":"allhumans","name":"Identification with Humanity Scale","description":"What groups are you most loyal to?   Whom do you identify with most?","key":"old/allhumans.xlsx","createdAt":"2021-10-20T22:17:17.568Z","updatedAt":"2021-10-20T22:22:36.569Z","__v":0}
      // console.log("getDownload datareq :"+","+JSON.stringify(response.data))
      // response.data.downloads.map((x) => {
      //   console.log("xxx: " + JSON.stringify(x));
      // });
      setDownloads(response.data.downloads);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);

  const downloadAlert = useCallback((url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomConfirm
            title="Download Link"
            onClose={onClose}
            text="Please click to start downloading."
          >
            <Container fluid className="p-0">
              <Row>
                <Col xs={12}>
                  <a
                    href={url}
                    // className="download-button text-dark"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Button
                      variant="outline-success"
                      className="w-100"
                      block="true"
                      onClick={() => {
                        onClose();
                        // console.log(url);
                      }}
                    >
                      Click Here to Download
                    </Button>
                  </a>
                </Col>
              </Row>
            </Container>
          </CustomConfirm>
        );
      },
    });
  }, []);

  const getDownloadLink = useCallback(async (_id,surveyId) => {
    try {
      // console.log("getDownloadLink test _id:" + _id);
      // console.log("getDownloadLink surveyId:"+surveyId)
      if(!cachedCsv[_id]){
        const response = await axiosInterceptor().post(
          "user/download-link-limit/" + _id,
          {
            surveyId:surveyId        
          }
        );
        cachedCsv[_id] = response.data
      }
      // console.log("getDownloadLink test response:" + JSON.stringify(response.data.ftype));
      if(cachedCsv[_id]?.ftype === "csv"){
        console.log("is csv")
        const blob = new Blob([cachedCsv[_id].data], { type: "csv" })
        const a = document.createElement('a')
        a.download = surveyId+".csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        return
      }
      // console.log("getDownloadLink test:" + JSON.stringify(response.data));
      downloadAlert(cachedCsv[_id].url);
    } catch (err) {
      console.log(err);
    }
  }, []);

  function hasNewSurvey(surveys){
    let res = false;
    surveys.forEach((i)=>{
      if(i.type === "new"){
        res = true
      }
    })
    return res
  }

  return (
    <Container fluid="true" className="text-center">
      {allowedPageAccess ? (
        <>
          {!downloads.includes(null) &&
            downloads.map((d) => (
              <DownloadCard
                name={d.name + ', ' + d.type}
                description={d.description}
                createdAt={d.createdAt}
                surveyId={d.surveyId}
                type={d.type}
                _id={d._id}
                getLink={getDownloadLink}
                kind={'new'}
                // getNew={getNewDownloadLink}
                downloading={downloading}
                compileLimit={true}
                timeLeft={d?.timeLeft}
              />
            ))}
          <Stack
          // direction={{ xs: 'column', md: 'row' }}
          // spacing={{ xs: 1, sm: 2, md: 4 }}
          // alignItems="center"
          // justifyContent='center'
          // width='50%'
          >
            <hr />
            <CustomBox>
              <Box
                // backgroundColor = 'red'
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '13rem',
                  border: '2px dashed #4267b2',
                  borderRadius: '20px',
                }}
              >
                <a
                  href="https://yourmorals-public-data.s3.us-west-2.amazonaws.com/Final+YourMorals+Agreement.pdf"
                  target="_blank"
                  download
                >
                  Download Agreement
                </a>

                {/* <Button onClick={onDownload}>Download Agreenment</Button> */}
                {/* <a href="https://drive.google.com/file/d/1tSxK91n9HU0AWLSYHXISjotb12KTAepG/view?usp=sharing" target="_blank" download="myFile">Download file</a> */}
              </Box>
            </CustomBox>

            <CustomBox>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '13rem',
                  border: '2px dashed #4267b2',
                  borderRadius: '20px',
                }}
                //   ref={wrapperRef}
                //   onDragEnter={onDragEnter}
                //   onDragLeave={onDragLeave}
                //   onDrop={onDragLeave}
              >
                <Stack
                  justifyContent="center"
                  sx={{ p: 1, textAlign: 'center' }}
                >
                  <Typography variant="body1" component="span">
                    <strong>Sign and Upload Agreement</strong>
                    <UploadImage
                      file={file}
                      setFile={setFile}
                      description={'Upload at most one png'}
                      maxImg={1}
                    />
                  </Typography>
                  <Typography variant="body2" component="span">
                    {/* JPG, JPEG, PNG */}
                  </Typography>
                </Stack>
              </Box>
            </CustomBox>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Box sx={{ minWidth: 130, maxWidth: 300 }}>
              <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id="demo-simple-select-label">SurveyID</InputLabel>
                <Select
                  defaultValue=""
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  value={selectedOption}
                  label="SurveyID"
                  onChange={handleLabel}
                >
                  {allSurveyIds.map((survey) => {
                    return <MenuItem value={survey}>{survey.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                {hasNewSurvey(selectedOption) ? (
                  <>
                    <InputLabel id="demo-simple-select-label">
                      Demograph
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectDemos}
                      onChange={handleDemoPropChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {demoPros.map((prop) => (
                        <MenuItem key={prop} value={prop}>
                          <Checkbox checked={selectDemos.indexOf(prop) > -1} />
                          <ListItemText primary={prop} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  "*Old data doesn't contain demographic info.\n Some new survey doesn't contain specific demographic variables"
                )}
              </FormControl>
            </Box>
            <Button
              variant="outlined"
              disabled={file.length !== 1 || !selectedOption}
              onClick={onSubmit}
            >
              Submit Request
            </Button>
          </Stack>

          <Stack>
            {/* <hr/>
        <h3> Your requests </h3> */}
          </Stack>
        </>
      ): (<div>
        You are not allowed to access this page. If you believe this is an error, please contact an admin.
      </div>)}
    </Container>
  )};
  